import React from "react";
import capitalizedFullName from "../../lib/functions/capitalizedFullName";

const ErrorPass = (props) => {
  return (
    <div className="flex flex-col items-center justify-center min-h-[420px] lg:min-h-0 lg:h-full p-4 lg:p-6 rounded-[20px] shadow-custom">
      <p className="text-red text-2xl font-bold mb-4 max-w-2xl">
        {props?.isError ? capitalizedFullName(props?.isError) : ""}
      </p>
      <p className="text-first text-lg">
        Kindly double-check the pass and try again
      </p>
    </div>
  );
};

export default ErrorPass;
