import React, { useState, useEffect } from "react";
import BarcodeReader from "react-barcode-reader";
import Lottie from "lottie-react";
import Header from "./Layout/Header";
import LeftTabs from "./Layout/LeftTabs";
import StatPage from "./StatPage";
import ScanPage from "./ScanPage";
import useUser from "../../hooks/useUser";
import request from "../../lib/api";
import { passApi } from "../../lib/api/pass";
import { KEYS } from "../../lib/constants";
import loading from "../../assets/lotties/loading.json";

const HomePage = () => {
  const [verified, setVerified] = useState(false);
  const [isScanning, setIsScanning] = useState(false);
  const [isScanned, setIsScanned] = useState(false);
  const [pass, setPass] = useState(null);
  const [status, setStatus] = useState("");
  const [showPage, setShowPage] = useState("shop");
  const [transactionsCustomerData, setTransactionsCustomerData] = useState([]);
  const [transactionsClientData, setTransactionsClientData] = useState([]);
  const [totalTransactionsClientData, setTotalTransactionsClientData] =
    useState("");
  const [isError, setIsError] = useState(null);
  const [showBarcodeReader, setShowBarcodeReader] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [totalRedeems, setTotalRedeems] = useState(0);
  const [totalPasses, setTotalPasses] = useState(0);
  const [passMedia, setPassMedia] = useState(null);
  const { user: userInfo } = useUser();
  const branch = JSON.parse(window.localStorage.getItem(KEYS.BRANCH));

  // Check if user is authorized
  useEffect(() => {
    setIsLoading(true);
    const token = window.localStorage.getItem(KEYS.TOKEN);
    if (!!!token) {
      window.location = "/login";
    } else {
      setVerified(true);
    }
  }, []);

  // Get Pass Data
  const fetchPassData = async (serialNumber, _status) => {
    setIsError(null);
    setIsScanned(false);
    setIsScanning(true);
    setShowPage("scan");
    setStatus(_status);
    try {
      const _pass = await passApi
        .getBySerialNumber(serialNumber)
        .catch((error) => {
          setIsError(error.message);
        });
      setPass(_pass);
      setIsScanned(true);
    } catch (error) {
      console.error(error.message);
      setIsScanned(false);
    } finally {
      setIsScanning(false);
      setStatus("Scanning");
    }
  };

  // Add Points
  const addPoints = async (points) => {
    try {
      setIsScanned(false);
      setIsScanning(true);
      setStatus("Adding");
      await passApi.addPoints(pass.serialNumber, points).catch((error) => {
        console.error(error.message);
      });
      fetchPassData(pass.serialNumber, "Adding");
    } catch (error) {
      console.error(error.message);
    } finally {
      setIsScanned(true);
      setIsScanning(false);
    }
  };

  // Remove Points
  const removePoints = async (points) => {
    try {
      setIsScanned(false);
      setIsScanning(true);
      setStatus("Removing");
      await passApi.removePoints(pass.serialNumber, points).catch((error) => {
        console.error(error.message);
      });
      fetchPassData(pass.serialNumber, "Removing");
    } catch (error) {
      console.error(error.message);
    } finally {
      setIsScanned(true);
      setIsScanning(false);
    }
  };

  // Redeem Points
  const redeemPoints = async (points, note) => {
    try {
      setIsScanned(false);
      setIsScanning(true);
      setStatus("Redeeming");
      await passApi
        .redeemPoints(pass.serialNumber, points, note)
        .catch((error) => {
          throw error;
        });
      fetchPassData(pass.serialNumber, "Redeeming");
    } catch (error) {
      console.error(error.message);
      alert(error.message);
    } finally {
      setIsScanned(true);
      setIsScanning(false);
    }
  };

  // Get total redeems for the branch
  useEffect(() => {
    request
      .get(`/transaction/redeem-count`)
      .then((response) => setTotalRedeems(response))
      .catch((error) => console.error(error.message));
  }, []);

  // Get transactions for client
  useEffect(() => {
    request
      .get(`/transaction/company/${userInfo?.company.id}/branch/${branch.id}`, {
        params: { page: 1, limit: 100 },
      })
      .then((response) => {
        setTransactionsClientData(response.data);
        setTotalTransactionsClientData(response.metaData.totalCount);
      })
      .catch((error) => console.error(error.message))
      .finally(() => {
        setIsLoading(false);
      });
  }, [userInfo?.company.id, branch?.id, status]);

  // Get transactions for customer
  useEffect(() => {
    request
      .get(`/transaction/pass/${pass?.id}`, {
        params: { page: 1, limit: 100 },
      })
      .then((response) => {
        setTransactionsCustomerData(response.data);
      })
      .catch((error) => console.error(error.message));
  }, [pass?.id, status]);

  // Get all passes for branch
  useEffect(() => {
    request
      .get("/pass?page=1&limit=10")
      .then((response) => {
        setTotalPasses(response.metadata.totalCount);
      })
      .catch((error) => console.error(error.message));
  }, []);

  // Get Pass Media
  useEffect(() => {
    if (pass) {
      request
        .get(
          `/media/download/pass.json?folder=${pass?.IdentifierId}&target=identifier`
        )
        .then((response) => {
          setPassMedia(response);
        })
        .catch((error) => console.error(error.message));
    }
  }, [pass]);

  return (
    <>
      {verified && !isLoading && (
        <div className="flex flex-col lg:h-screen py-4 px-4 xl:px-8">
          <Header userInformation={userInfo} branchInfo={branch} />
          <section className="flex flex-1 gap-4 xl:gap-6 mt-8 w-full flex-wrap lg:flex-nowrap flex-col lg:flex-row">
            <LeftTabs showPage={showPage} setShowPage={setShowPage} />
            {showPage === "shop" && (
              <StatPage
                transactionsClientData={transactionsClientData}
                totalRedeems={totalRedeems}
                totalTransactionsClientData={totalTransactionsClientData}
                totalPasses={totalPasses}
                fetchPassData={fetchPassData}
              />
            )}
            {showPage === "scan" && (
              <ScanPage
                isScanning={isScanning}
                isScanned={isScanned}
                pass={pass}
                addPoints={addPoints}
                removePoints={removePoints}
                redeemPoints={redeemPoints}
                status={status}
                transactionsCustomerData={transactionsCustomerData}
                isError={isError}
                setShowBarcodeReader={setShowBarcodeReader}
                passMedia={passMedia}
              />
            )}
            {showBarcodeReader && (
              <BarcodeReader
                onError={(error) => console.error(error)}
                onScan={(data) => fetchPassData(data, "Scanning")}
                key={"homePage"}
              />
            )}
          </section>
        </div>
      )}
      {isLoading && (
        <section className="flex flex-col h-screen px-8 py-4 items-center justify-center">
          <div className="text-center w-full -mt-40 max-w-xs mx-auto">
            <Lottie animationData={loading} loop={true} />
            <p className="text-first font-medium -mt-14">Loading...</p>
          </div>
        </section>
      )}
    </>
  );
};

export default HomePage;
