import React, { useState, useEffect } from "react";
import GridList from "./GridList";
import Popup from "./Popup";

import addIcon from "../../../assets/icons/add.svg";
import removeIcon from "../../../assets/icons/remove.svg";
import redeemIcon from "../../../assets/icons/redeem.svg";

const CREDIT_ACTIONS = [
  {
    title: "Add",
    icon: addIcon,
  },
  {
    title: "Remove",
    icon: removeIcon,
  },
  {
    title: "Redeem",
    icon: redeemIcon,
  },
];

const CreditActions = (props) => {
  const { pass, addPoints, removePoints, redeemPoints, setShowBarcodeReader } =
    props;

  const [active, setActive] = useState(0);
  // IF 0, ADD AND STAMP IS ACTIVE
  // IF 1, REMOVE AND STAMP IS ACTIVE
  // IF 2, REDEEM AND STAMP IS ACTIVE
  // **********************************
  // IF 3, ADD AND POINTS IS ACTIVE
  // IF 4, REMOVE AND POINTS IS ACTIVE
  // IF 5, REDEEM AND POINTS IS ACTIVE
  // **********************************
  // IF 6, REDEEM AND VOUCHER IS ACTIVE

  const [value, setValue] = useState(0);
  const [showPopup, setShowPopup] = useState(false);

  const showPopupHandler = () => {
    setShowPopup(true);
    setShowBarcodeReader(false);
  };

  const closePopupHandler = () => {
    setShowPopup(false);
    setShowBarcodeReader(true);
    if (active === 2 || active === 3 || active === 4 || active === 5)
      setActive(-1);
  };

  const okPopupHandler = (note, redPoints) => {
    if (active === 0) addPoints(value);
    if (active === 1) removePoints(value);
    if (active === 2) redeemPoints(pass?.points, note);
    if (active === 3) addPoints(note);
    if (active === 4) removePoints(note);
    if (active === 5) redeemPoints(redPoints, note);
    if (active === 6) redeemPoints(pass?.points, "Voucher");
    setShowPopup(false);
    setShowBarcodeReader(true);
    setActive(-1);
  };

  useEffect(() => {
    if (showPopup) document.body.style.overflowY = "hidden";
    if (!showPopup) document.body.style.overflowY = "auto";
  }, [showPopup]);

  if (pass?.identifier?.type === "CARDMENA_STAMP") {
    return (
      <>
        <div className="flex justify-between mb-8">
          {CREDIT_ACTIONS.map((action, index) => {
            return (
              <div
                key={index}
                onClick={() => {
                  setActive(index);
                  if (action.title === "Redeem") showPopupHandler();
                  setValue(0);
                }}
                className={`action select-none cursor-pointer ${
                  action.title === "Add"
                    ? "add"
                    : action.title === "Remove"
                    ? "remove"
                    : "redeem"
                } ${index === active ? "active" : ""}`}
              >
                <div className="bg-secondary p-6 rounded-[10px] mb-2 transition">
                  <img src={action.icon} alt={action.title} />
                </div>
                <p className="text-xs text-first font-medium text-center">
                  {action.title}
                </p>
              </div>
            );
          })}
        </div>
        <GridList
          showPopup={showPopupHandler}
          value={value}
          setValue={setValue}
          numbers={pass?.points}
          active={active}
          maxPoint={pass?.identifier?.max_points}
        />
        {showPopup && (
          <Popup
            popupType={active}
            points={pass?.points}
            closeHandler={closePopupHandler}
            okHandler={okPopupHandler}
            minimum_redeem_points={pass?.identifier?.minimum_redeem_points}
            max_redeem_points={pass?.identifier?.max_redeem_points}
          />
        )}
      </>
    );
  }

  if (pass?.identifier?.type === "CARDMENA_POINT") {
    return (
      <>
        <div className="flex justify-between mb-8">
          {CREDIT_ACTIONS.map((action, index) => {
            return (
              <div
                key={index}
                onClick={() => {
                  setActive(index + 3);
                  showPopupHandler();
                }}
                className={`action select-none cursor-pointer ${
                  action.title === "Add"
                    ? "add"
                    : action.title === "Remove"
                    ? "remove"
                    : "redeem"
                } ${index + 3 === active ? "active" : ""} ${index + 3}`}
              >
                <div className="bg-secondary p-6 rounded-[10px] mb-2">
                  <img src={action.icon} alt={action.title} />
                </div>
                <p className="text-xs text-first font-medium text-center">
                  {action.title}
                </p>
              </div>
            );
          })}
        </div>
        {showPopup && (
          <Popup
            popupType={active}
            points={pass?.points}
            closeHandler={closePopupHandler}
            okHandler={okPopupHandler}
            minimum_redeem_points={pass?.identifier?.minimum_redeem_points}
            max_redeem_points={pass?.identifier?.max_redeem_points}
          />
        )}
      </>
    );
  }

  if (pass?.identifier?.type === "CARDMENA_VOUCHER") {
    return (
      <>
        {!pass?.voided && (
          <>
            <div
              onClick={() => {
                setActive(6);
                showPopupHandler();
              }}
              className={`action select-none cursor-pointer redeem w-full ${
                active === 6 ? "active" : ""
              }`}
            >
              <div className="bg-secondary p-6 rounded-[10px] mb-2">
                <img src={redeemIcon} alt="Redeem" className="mx-auto" />
              </div>
              <p className="text-sm text-first font-medium text-center">
                Redeem
              </p>
            </div>
            {showPopup && (
              <Popup
                popupType={active}
                points={pass?.points}
                closeHandler={closePopupHandler}
                okHandler={okPopupHandler}
                minimum_redeem_points={pass?.identifier?.minimum_redeem_points}
                max_redeem_points={pass?.identifier?.max_redeem_points}
              />
            )}
          </>
        )}
        {pass?.voided && (
          <div className="bg-secondary p-6 rounded-[10px]">
            <p className="text-sm text-first font-medium text-center">
              This voucher has already been redeemed.
            </p>
          </div>
        )}
      </>
    );
  }
};

export default CreditActions;
