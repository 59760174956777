import React from "react";
import Table from "../customer-table/Table";

const CustomerTransactions = (props) => {
  return (
    <>
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-header font-medium">Pass Transactions</h3>
        {props.data.length > 10 && (
          <button
            className="underline text-first text-xs"
            onClick={props.seeAllHandler}
          >
            {props.seeAll ? "Back" : "See All"}
          </button>
        )}
      </div>
      {props.data.length !== null && props.data.length ? (
        <>
          {props.data.length > 0 ? (
            <Table data={props.data} seeAll={props.seeAll} />
          ) : (
            <p className="mt-8">No transactions have been made yet.</p>
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default CustomerTransactions;
