import React from "react";

const Button = (props) => {
  const { children, theme, onClick, isDisable, isFullWidth, type } = props;

  let cssStyle, hoverStyle;

  switch (theme) {
    case "primary":
      cssStyle =
        "bg-main rounded-[10px] font-semibold text-sm text-white px-14 py-2 leading-7 transition";
      hoverStyle = "hover:bg-opacity-5 hover:text-main transition";
      break;
    case "secondary":
      cssStyle =
        "bg-secondary rounded-[10px] font-semibold text-sm text-first px-14 py-2 leading-7 transition";
      hoverStyle = "hover:bg-third hover:text-white transition";
      break;
    default:
      cssStyle =
        "bg-main rounded-[10px] font-semibold text-sm text-white px-14 py-2 leading-7 transition";
      hoverStyle = "hover:bg-opacity-5 hover:text-main transition";
      break;
  }

  return (
    <button
      onClick={onClick}
      disabled={isDisable}
      type={type}
      className={
        isDisable
          ? isFullWidth
            ? `${cssStyle} cursor-not-allowed opacity-40 w-full`
            : `${cssStyle} cursor-not-allowed opacity-40`
          : isFullWidth
          ? `${cssStyle + " w-full " + hoverStyle}`
          : `${cssStyle + " " + hoverStyle}`
      }
    >
      {children}
    </button>
  );
};
export default Button;
