import React from "react";

import LogoImg from "../../assets/logos/logo.png";

const CardmenaLogo = () => {
  return (
    <div className="flex justify-center w-full lg:w-1/3 order-1 lg:order-2">
      <img src={LogoImg} alt="Logo" className="mx-auto max-w-[60%]" />
    </div>
  );
};

export default CardmenaLogo;
