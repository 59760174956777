import React from "react";
import Lottie from "lottie-react";
import loading from "../../assets/lotties/loading.json";

const Scanning = (props) => {
  return (
    <div className="flex items-center justify-center min-h-[420px] lg:min-h-0 lg:h-full p-4 lg:p-6 rounded-[20px] shadow-custom">
      <div className="text-center">
        <Lottie animationData={loading} loop={true} />
        <p className="text-first font-medium -mt-6">{props.status}...</p>
      </div>
    </div>
  );
};

export default Scanning;
