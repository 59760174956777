import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Button from "../Ui/Button";

import CardmenaLogo from "../../assets/logos/logo.png";

const SelectBranchForm = (props) => {
  const formik = useFormik({
    initialValues: {
      branch: "",
    },
    onSubmit: () => {
      const selectedBranch = props.branches.find(
        (branch) => branch.id === formik.values.branch
      );
      props.onSubmit(selectedBranch);
    },
    validationSchema: Yup.object({
      branch: Yup.string().required("Your company branch is required"),
    }),
  });

  return (
    <div className="rounded-[20px] shadow-custom py-8 lg:py-10 px-4 lg:px-8 max-w-lg mx-auto my-auto">
      <div className="flex items-center justify-center">
        <div className="w-full">
          <img
            src={CardmenaLogo}
            alt="Cardmena Logo"
            className="mx-auto max-w-[60%]"
          />
          <h1 className="text-main text-center text-2xl mt-8 mb-4 font-semibold leading-8">
            Branches
          </h1>
          <p className="text-first text-center mb-4">
            Select a branch to complete activation
          </p>
          <form>
            <div className="mt-4 mb-4">
              <select
                required
                type="text"
                id="branch"
                name="branch"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.branch}
                className="w-full outline-none border border-second p-4 rounded text-first minimal"
              >
                <option value="" defaultValue disabled>
                  Select a branch..
                </option>
                {props.branches.map((branch) => (
                  <option value={branch.id} key={branch.id}>
                    {branch.name}
                  </option>
                ))}
              </select>
              {formik.errors.branch && formik.touched.branch && (
                <p className="text-left text-red text-sm mt-2">
                  {formik.errors.branch}
                </p>
              )}
            </div>
            <Button
              onClick={() => formik.handleSubmit()}
              theme={"primary"}
              isFullWidth={true}
              type={"button"}
            >
              Next
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SelectBranchForm;
