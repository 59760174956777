import React from "react";

const Empty = () => {
  return (
    <div className="flex items-center justify-center min-h-[420px] lg:min-h-0 lg:h-full p-4 lg:p-6 rounded-[20px] shadow-custom">
      <p className="text-first text-lg font-medium">Scan QR to View</p>
    </div>
  );
};

export default Empty;
