import React, { useState, useEffect } from "react";
import Lottie from "lottie-react";
import axios from "axios";
import useUser from "../../hooks/useUser";
import AuthForm from "../../components/LoginPage/AuthForm";
import loading from "../../assets/lotties/loading.json";
import SecretForm from "../../components/LoginPage/SecretForm";
import SelectBranchForm from "../../components/LoginPage/SelectBranchForm";
import { KEYS } from "../../lib/constants";

function LoginPage() {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState("");
  const [notVerified, setNotVerified] = useState(false);
  const [isActivated, setIsActivated] = useState(false);
  const [branches, setBranches] = useState([]);
  const [selectedBranch, setSetSelectedBranch] = useState("");

  const { login } = useUser();

  // Check if user is authorized and if branch is activated
  useEffect(() => {
    setIsLoading(true);
    setIsError("");
    const token = window.localStorage.getItem(KEYS.TOKEN);
    const branch = window.localStorage.getItem(KEYS.BRANCH);
    if (token) window.location = "/";
    else if (branch) {
      setIsActivated(true);
    } else {
      setIsActivated(false);
    }
    setNotVerified(true);
    setIsLoading(false);
  }, []);

  const loginHandler = async (formData) => {
    setIsLoading(true);
    formData.branchId = selectedBranch;
    try {
      await login(formData);
    } catch (error) {
      setIsError(error?.error || error?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const secretHandler = async (secretCode) => {
    setIsLoading(true);
    try {
      const response = await axios.get(`/branch/company/secret/${secretCode}`, {
        headers: {
          "x-secret": "x&^$2r3H2!3s%",
        },
      });
      if (response.data.length > 0) {
        setBranches(response.data);
        setIsError("");
      } else setIsError("Incorrect company secret code. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const branchHandler = (branch) => {
    localStorage.setItem(KEYS.BRANCH, JSON.stringify(branch));
    setSetSelectedBranch(branch.id);
    setIsActivated(true);
  };

  const deactivatedHandler = () => {
    localStorage.removeItem(KEYS.BRANCH);
    window.location.reload();
  };

  return (
    <section className="flex flex-col min-h-[650px] xl:min-h-0 xl:h-screen px-4 xl:px-8 py-4 items-center justify-center">
      {!isActivated && notVerified && !isLoading && branches.length === 0 && (
        <SecretForm isError={isError} onSubmit={secretHandler} />
      )}
      {!isActivated && notVerified && !isLoading && branches.length > 0 && (
        <SelectBranchForm branches={branches} onSubmit={branchHandler} />
      )}
      {isActivated && notVerified && !isLoading && (
        <AuthForm
          isError={isError}
          onSubmit={loginHandler}
          deactivate={deactivatedHandler}
        />
      )}
      {isLoading && (
        <div className="text-center w-full -mt-40 max-w-xs mx-auto">
          <Lottie animationData={loading} loop={true} />
          <p className="text-first font-medium -mt-14">Loading...</p>
        </div>
      )}
    </section>
  );
}

export default LoginPage;
