import React from "react";
import capitalizedFullName from "../../lib/functions/capitalizedFullName";

const PropertyDetails = (props) => {
  let branchName, username, companyName, companyLogo;

  if (props) {
    branchName = capitalizedFullName(props.branchName);
    username = capitalizedFullName(props.username);
    companyName = capitalizedFullName(props.companyName);
    companyLogo = props.companyLogo;
  }

  return (
    <div className="flex items-center gap-4 lg:gap-6 w-full lg:w-1/3 order-2 lg:order-1 justify-center lg:justify-start">
      <div>
        <img src={companyLogo} alt={companyName} className="h-8" />
      </div>
      <div>
        <h3 className="text-sm">
          {companyName} - {branchName} Branch
        </h3>
        <p className="text-second text-xs">Logged in as {username}</p>
      </div>
    </div>
  );
};

export default PropertyDetails;
