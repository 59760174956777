import React from "react";
import Table from "./client-table/Table";

const ClientTransactions = (props) => {
  console.log(props.data);
  return (
    <div
      className={`p-4 xl:p-6 rounded-[20px] shadow-custom ${
        props.seeAll ? "w-full" : "w-full lg:w-2/3"
      }`}
    >
      <div className="flex justify-between items-center mb-8">
        <h3 className="text-header font-medium">Branch Transactions</h3>
        {props.data.length > 9 && (
          <button
            className="underline text-first text-xs"
            onClick={props.seeAllHandler}
          >
            {props.seeAll ? "Back" : "See All"}
          </button>
        )}
      </div>
      {props.data.length !== null && props.data.length ? (
        <>
          {props.data.length > 0 ? (
            <Table
              data={props.data}
              seeAll={props.seeAll}
              fetchPassData={props.fetchPassData}
            />
          ) : (
            <p>No transactions have been made yet.</p>
          )}
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default ClientTransactions;
