import React from "react";
import Row from "./Row";

const Table = (props) => {
  const fullTableStyle =
    "max-h-[510px] overflow-x-hidden overflow-y-scroll pr-6";

  const numOfEntries = props.seeAll
    ? props.data.length
    : props.data.length >= 9
    ? 9
    : props.data.length;

  return (
    <div className={`${props.seeAll ? fullTableStyle : ""}`}>
      <div
        className={`grid grid-cols-custom8 pb-2 mb-2 items-center border-b border-third`}
      >
        <div className="text-center">
          <span className="text-sm font-semibold text-header">Id</span>
        </div>
        <div className="text-center">
          <span className="text-sm font-semibold text-header">Employee</span>
        </div>
        <div className="text-center">
          <span className="text-sm font-semibold text-header">
            Serial Number
          </span>
        </div>
        <div className="text-center">
          <span className="text-sm font-semibold text-header">Customer</span>
        </div>
        <div className="text-center">
          <span className="text-sm font-semibold text-header">Action</span>
        </div>
        <div className="text-center">
          <span className="text-sm font-semibold text-header">Pass Type</span>
        </div>
        <div className="text-center">
          <span className="text-sm font-semibold text-header">Date & Time</span>
        </div>
      </div>
      {props.data.slice(0, numOfEntries).map((item, index) => {
        return (
          <Row
            key={item.id}
            order={index}
            numOfEntries={numOfEntries}
            data={item}
            fetchPassData={props.fetchPassData}
          />
        );
      })}
    </div>
  );
};

export default Table;
