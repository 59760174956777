import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import TextField from "@mui/material/TextField";
import Button from "../Ui/Button";

import CardmenaLogo from "../../assets/logos/logo.png";

const AuthForm = (props) => {
  const formik = useFormik({
    initialValues: {
      phone: "",
      password: "",
    },
    onSubmit: () => {
      props.onSubmit(formik.values);
      formik.resetForm();
    },
    validationSchema: Yup.object({
      phone: Yup.string().required("Your phone number is required"),
      password: Yup.string().required("Your password is required"),
    }),
  });

  return (
    <div className="rounded-[20px] shadow-custom py-8 lg:py-10 px-4 lg:px-8 max-w-lg mx-auto my-auto">
      <div className="flex items-center justify-center mb-8">
        <div className="w-full">
          <img
            src={CardmenaLogo}
            alt="Cardmena Logo"
            className="mx-auto max-w-[60%]"
          />
          <h1 className="text-main text-center text-2xl mt-8 mb-8 font-semibold leading-8">
            Login
          </h1>
          {props.isError !== "" && (
            <p className="text-red text-sm mb-4 text-center">{props.isError}</p>
          )}
          <form>
            <div className="mb-4">
              <TextField
                required
                type="tel"
                id="phone"
                name="phone"
                label="Phone Number"
                placeholder="Enter your phone number here.."
                fullWidth
                error={formik.errors.phone && formik.touched.phone}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.phone}
              />
              {formik.errors.phone && formik.touched.phone && (
                <p className="text-left text-red text-sm mt-2">
                  {formik.errors.phone}
                </p>
              )}
            </div>
            <div className="mb-4">
              <TextField
                required
                type="password"
                id="password"
                name="password"
                label="Password"
                placeholder="Enter your password here.."
                fullWidth
                error={formik.errors.password && formik.touched.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
              />
              {formik.errors.password && formik.touched.password && (
                <p className="text-left text-red text-sm my-1">
                  {formik.errors.password}
                </p>
              )}
            </div>
            <Button
              onClick={() => {
                formik.handleSubmit();
              }}
              theme={"primary"}
              isFullWidth={true}
              type={"submit"}
            >
              Login
            </Button>
          </form>
        </div>
      </div>
      <Button
        onClick={props.deactivate}
        theme={"secondary"}
        isFullWidth={true}
        type={"button"}
      >
        Deactivate Device
      </Button>
    </div>
  );
};

export default AuthForm;
