import React from "react";
import Icon from "./Icon";

const Dashboard = (props) => {
  const BRANCH_STATISTICS = [
    {
      id: "icon1",
      image: require("../../assets/icons/branch-orders.png"),
      number: props.totalTransactionsClientData,
      title: "Total Transactions",
    },
    {
      id: "icon2",
      image: require("../../assets/icons/total-rewards.png"),
      number: props.totalRedeems,
      title: "Total Redeems",
    },
    {
      id: "icon3",
      image: require("../../assets/icons/total-stamps.png"),
      number: "159",
      title: "Total Stamps",
    },
    {
      id: "icon4",
      image: require("../../assets/icons/total-visits.png"),
      number: props.totalPasses,
      title: "Total Passes",
    },
  ];

  return (
    <div className="w-full lg:w-1/3 p-4 xl:p-6 rounded-[20px] shadow-custom">
      <h3 className="text-header font-medium mb-8">Dashboard</h3>
      <div className="grid gap-y-6">
        {BRANCH_STATISTICS.map((item) => {
          return (
            <Icon
              key={item.id}
              id={item.id}
              title={item.title}
              image={item.image}
              number={item.number}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Dashboard;
