import React from "react";
import dateFormatted from "../../../lib/functions/dateFormatted";
import capitalizedFullName from "../../../lib/functions/capitalizedFullName";

const ClientInfo = (props) => {
  const { pass, passMedia } = props;

  return (
    <>
      <div
        className="flex p-3 rounded-lg items-center justify-between mb-4"
        style={{
          backgroundColor: passMedia?.backgroundColor,
        }}
      >
        <div>
          <img
            src={`https://api.cardmena.com/media/download/logo@2x.png?target=identifier&folder=${passMedia?.passTypeIdentifier}`}
            alt={passMedia?.passTypeIdentifier}
            className="max-w-[160px]"
          />
        </div>
        <div className="text-right">
          <p
            className="uppercase"
            style={{
              color: passMedia?.labelColor,
            }}
          >
            Name
          </p>
          <p
            className="uppercase"
            style={{
              color: passMedia?.foregroundColor,
            }}
          >
            {pass?.customerDetails?.name}
          </p>
        </div>
      </div>
      <div className="mb-4">
        <img
          src={
            pass?.identifier?.type === "CARDMENA_STAMP"
              ? `https://api.cardmena.com/media/download/${pass?.points}.png?target=identifier&folder=${passMedia?.passTypeIdentifier}/stamps`
              : `https://api.cardmena.com/media/download/strip.png?target=identifier&folder=${passMedia?.passTypeIdentifier}`
          }
          alt={passMedia?.passTypeIdentifier}
          className="rounded-lg"
        />
      </div>
      <div className="mb-8">
        <div className="flex justify-between items-center border-b pb-2 border-third">
          <h5 className="text-second text-sm">Pass Type</h5>
          <p
            style={{
              color: passMedia?.backgroundColor,
            }}
            className="text-sm font-medium"
          >
            {pass?.identifier?.type}
          </p>
        </div>
        <div className="flex justify-between items-center pt-2 border-b pb-2 border-third">
          <h5 className="text-second text-sm">Pass Serial Number</h5>
          <p
            style={{
              color: passMedia?.backgroundColor,
            }}
            className="text-sm font-medium"
          >
            {pass?.serialNumber}
          </p>
        </div>
        <div className="flex justify-between items-center pt-2 border-b pb-2 border-third">
          <h5 className="text-second text-sm">Last Used</h5>
          <p
            style={{
              color: passMedia?.backgroundColor,
            }}
            className="text-sm font-medium"
          >
            {dateFormatted(pass?.lastUpdate).split(" ")[0]}
          </p>
        </div>
        <div className="flex justify-between items-center pt-2">
          <h5 className="text-second text-sm">Total Redeems</h5>
          <p
            style={{
              color: passMedia?.backgroundColor,
            }}
            className="text-sm font-medium"
          >
            {pass?.passStatus?.totalRedeems}
          </p>
        </div>
      </div>
      <h3 className="text-header font-medium">Customer Information</h3>
      <div className="flex justify-between mt-4">
        <div>
          <h5 className="text-second text-sm mb-1">Gender</h5>
          <p
            style={{
              color: passMedia?.backgroundColor,
            }}
            className="text-sm"
          >
            {capitalizedFullName(pass?.customerDetails?.gender)}
          </p>
        </div>
        <div>
          <h5 className="text-second text-sm mb-1">Phone Number</h5>
          <p
            style={{
              color: passMedia?.backgroundColor,
            }}
            className="text-sm"
          >
            {pass?.customerDetails?.phone}
          </p>
        </div>
        <div>
          <h5 className="text-second text-sm mb-1">Date of Birth</h5>
          <p
            style={{
              color: passMedia?.backgroundColor,
            }}
            className="text-sm"
          >
            {dateFormatted(pass?.customerDetails?.birthDate) !== "Not Provided"
              ? dateFormatted(pass?.customerDetails?.birthDate).split(" ")[0]
              : dateFormatted(pass?.customerDetails?.birthDate)}
          </p>
        </div>
      </div>
    </>
  );
};

export default ClientInfo;
