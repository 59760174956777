import { KEYS } from "../lib/constants";
import request from "../lib/api";

export default function useUser() {
  const token = window?.localStorage.getItem(KEYS.TOKEN);
  const refresh_token = window?.localStorage.getItem(KEYS.REFRESH_TOKEN);
  const user = window?.localStorage.getItem(KEYS.USER);

  const login = async ({ phone, password, branchId }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await request.post(`/auth`, {
          phone,
          password,
          branchId,
        });
        const token = response.tokens.access_token;
        const refresh_token = response.tokens.refresh_token;
        const user = JSON.stringify(response.user);
        localStorage.setItem(KEYS.TOKEN, token);
        localStorage.setItem(KEYS.REFRESH_TOKEN, refresh_token);
        localStorage.setItem(KEYS.USER, user);
        window.location = "/";
        resolve(user);
      } catch (e) {
        reject(e);
      }
    });
  };

  const getMe = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await request.get(`/user/me`);
        localStorage.removeItem(KEYS.USER);
        const user = JSON.stringify(response);
        localStorage.setItem(KEYS.USER, user);
        resolve(user);
      } catch (e) {
        reject(e);
      }
    });
  };

  const logout = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        localStorage.removeItem(KEYS.TOKEN);
        localStorage.removeItem(KEYS.REFRESH_TOKEN);
        localStorage.removeItem(KEYS.USER);
        window.location = "/login";
        resolve(null);
      } catch (e) {
        reject(e);
      }
    });
  };

  return {
    token,
    login,
    logout,
    getMe,
    isLoggedIn: !!token,
    refresh_token,
    user: JSON.parse(user),
  };
}
