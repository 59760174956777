import React from "react";
import { BiEdit } from "react-icons/bi";
import dateFormatted from "../../../lib/functions/dateFormatted";
import capitalizedFullName from "../../../lib/functions/capitalizedFullName";

const Row = (props) => {
  const { createdAt, pass, points, type, user } = props?.data;

  const textColor =
    type === "REDEEM"
      ? "text-green"
      : type === "ADD"
      ? "text-main"
      : "text-red";

  const editTransaction = () => {
    props.fetchPassData(pass?.serialNumber, "Opening");
  };

  return (
    <div
      className={`grid grid-cols-custom8 pb-2 mb-2 items-center ${
        props?.numOfEntries - 1 === props?.order ? "" : "border-b border-third"
      }`}
    >
      <div className="text-center">
        <img
          className="mx-auto rounded-xl w-8"
          src={`https://api.cardmena.com/media/download/icon@2x.png?target=identifier&folder=${pass?.IdentifierId}`}
          alt={pass?.IdentifierId}
          title={pass?.IdentifierId}
        />
      </div>
      <div className="text-center">
        <span className="text-sm">
          {user?.name ? capitalizedFullName(user?.name) : ""}
        </span>
      </div>
      <div className="text-center">
        <span className="text-sm font-medium">{pass?.serialNumber}</span>
      </div>
      <div className="text-center">
        <span className="text-sm font-medium">
          {pass?.customerDetails?.name
            ? capitalizedFullName(pass?.customerDetails?.name)
            : ""}
        </span>
      </div>
      <div className="text-center">
        <span className={`text-sm font-medium ${textColor}`}>
          {type === "ADD"
            ? "+" + points
            : type === "REMOVE"
            ? "-" + points
            : "REDEEM"}
        </span>
      </div>
      <div className="text-center">
        <span className="text-sm font-medium">
          {pass?.identifier?.type
            ? capitalizedFullName(pass?.identifier?.type.split("_")[1])
            : ""}
        </span>
      </div>
      <div className="text-center">
        <span className="text-sm font-light text-first">
          {createdAt ? dateFormatted(createdAt) : ""}
        </span>
      </div>
      <div
        className="text-center hover:cursor-pointer"
        onClick={editTransaction}
        title="Edit"
      >
        <BiEdit className="text-lg mx-auto" />
      </div>
    </div>
  );
};

export default Row;
