export default function dateFormatted(date) {
  if (date) {
    const inputDate = new Date(date);
    const formattedDate = `${inputDate.getFullYear()}/${(
      inputDate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}/${inputDate
      .getDate()
      .toString()
      .padStart(2, "0")} ${inputDate
      .getHours()
      .toString()
      .padStart(2, "0")}:${inputDate.getMinutes().toString().padStart(2, "0")}`;
    return formattedDate;
  } else return "Not Provided";
}
