import React from "react";
import dateFormatted from "../../../lib/functions/dateFormatted";
import capitalizedFullName from "../../../lib/functions/capitalizedFullName";

const Row = (props) => {
  const backGroundColorClass =
    props?.data?.type === "ADD"
      ? "bg-main200"
      : props?.data?.type === "REMOVE"
      ? "bg-red200"
      : "bg-green200";

  const textColorClass =
    props?.data?.type === "ADD"
      ? "text-main"
      : props?.data?.type === "REMOVE"
      ? "text-red"
      : "text-green";

  return (
    <div
      className={`grid grid-cols-custom4 p-1.5 mb-1.5 rounded-[10px] ${backGroundColorClass}`}
    >
      <div className="text-center">
        <span className="text-xs font-medium">
          {props?.data?.branch?.name
            ? capitalizedFullName(props?.data?.branch?.name)
            : ""}
        </span>
      </div>
      <div className="text-center">
        <span className="text-xs font-medium">
          {props?.data?.user?.name
            ? capitalizedFullName(props?.data?.user?.name)
            : ""}
        </span>
      </div>
      <div className="text-center">
        <span className={`text-xs font-medium ${textColorClass}`}>
          {props?.data?.type === "ADD"
            ? "+" + props?.data?.points
            : props.data.type === "REMOVE"
            ? "-" + props?.data?.points
            : "REDEEM"}
        </span>
      </div>
      <div className="text-center">
        <span className="text-xs font-light text-first">
          {props?.data?.createdAt ? dateFormatted(props?.data?.createdAt) : ""}
        </span>
      </div>
    </div>
  );
};

export default Row;
