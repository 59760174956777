import request from ".";

export const passApi = {
  getBySerialNumber: (serialNumber: any) =>
    request.get(`/pass/${serialNumber}`),
  addPoints: (serialNumber: any, points: any) =>
    request.put(`/pass/add-points/${serialNumber}`, {
      points: parseInt(points),
      note: "Adding Points",
    }),
  removePoints: (serialNumber: any, points: any) =>
    request.put(`/pass/remove-points/${serialNumber}`, {
      points: parseInt(points),
      note: "Removing Points",
    }),
  redeemPoints: (serialNumber: any, points: any, note: any) =>
    request.put(`/pass/redeem/${serialNumber}`, {
      points: parseInt(points),
      note: note,
    }),
};
