import React from "react";

const Icon = (props) => {
  const { image, number, title } = props;

  return (
    <div className="flex items-center gap-6">
      <div>
        <img src={image} alt={title} />
      </div>
      <div>
        <h3 className="text-xl font-medium">{number}</h3>
        <p className="text-third font-medium">{title}</p>
      </div>
    </div>
  );
};

export default Icon;
