import React from "react";
import convertNumberToArray from "../../../lib/functions/convertNumberToArray";

const GridList = (props) => {
  const { showPopup, value, setValue, numbers, active, maxPoint } = props;

  const gridNumbers = active === 0 ? maxPoint - numbers : numbers;
  const arrayOfNumbers = convertNumberToArray(gridNumbers);

  return (
    <>
      {(active === 0 || active === 1) && (
        <div className="grid grid-cols-4 gap-3">
          {arrayOfNumbers.map((num) => {
            return (
              <div
                className={`gridNumber bg-secondary h-16 leading-[64px] rounded-[10px] text-center text-main text-xl font-semibold hover:text-white hover:bg-main cursor-pointer select-none transition ${
                  num === value ? "active" : ""
                }`}
                key={num}
                id={`value-${num}`}
                onClick={() => {
                  setValue(num);
                  showPopup();
                }}
              >
                {num}
              </div>
            );
          })}
        </div>
      )}
      {active === 0 && numbers === 8 && <p className="mt-4">Nothing to add.</p>}
      {active === 1 && numbers === 0 && (
        <p className="mt-4">Nothing to remove.</p>
      )}
    </>
  );
};

export default GridList;
