import React from "react";
import CardmenaLogo from "../../assets/logos/logo.png";

function ErrorPage() {
  return (
    <section className="flex flex-col min-h-[650px] xl:min-h-0 xl:h-screen px-4 xl:px-8 py-4 items-center justify-center">
      <div className="rounded-[20px] shadow-custom py-10 px-8 max-w-lg mx-auto my-auto">
        <div className="flex items-center justify-center">
          <div className="w-full">
            <img
              src={CardmenaLogo}
              alt="Cardmena Logo"
              className="mx-auto max-w-[60%]"
            />
            <h1 className="text-red text-center text-2xl mt-8 mb-8 font-semibold leading-8">
              Error 404
            </h1>
            <p className="text-md text-center">
              Oops! Could not find this page
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ErrorPage;
