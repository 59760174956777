import React, { useState } from "react";
import ClientInfo from "./scan-data/ClientInfo";
import CreditActions from "./scan-data/CreditActions";
import CustomerTransactions from "./scan-data/CustomerTransactions";

const PassInformation = (props) => {
  const [seeAll, setSeeAll] = useState(false);
  const {
    pass,
    addPoints,
    removePoints,
    redeemPoints,
    transactionsCustomerData,
    setShowBarcodeReader,
    passMedia,
  } = props;

  const seeAllHandler = () => {
    setSeeAll((prevStat) => !prevStat);
  };

  return (
    <div className="flex h-full gap-4 xl:gap-6 flex-wrap lg:flex-nowrap">
      {!seeAll && (
        <>
          <div className="w-full lg:w-1/3 p-4 xl:p-6 rounded-[20px] shadow-custom">
            <ClientInfo pass={pass} passMedia={passMedia} />
          </div>
          <div className="w-full lg:w-1/3 p-4 xl:p-6 rounded-[20px] shadow-custom">
            <h3 className="text-header font-medium mb-8">Credit Actions</h3>
            <CreditActions
              pass={pass}
              addPoints={addPoints}
              removePoints={removePoints}
              redeemPoints={redeemPoints}
              setShowBarcodeReader={setShowBarcodeReader}
            />
          </div>
        </>
      )}
      <div
        className={`p-4 xl:p-6 rounded-[20px] shadow-custom ${
          seeAll ? "w-full" : "w-full lg:w-1/3"
        }`}
      >
        <CustomerTransactions
          data={transactionsCustomerData}
          seeAllHandler={seeAllHandler}
          seeAll={seeAll}
        />
      </div>
    </div>
  );
};

export default PassInformation;
