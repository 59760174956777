import Axios from "axios";
import _ from "lodash";
import { KEYS } from "../constants";

const PRODUCTION_API = "https://api.cardmena.com";
const AUTH_HEADER = "x-auth-token";

let request = Axios;

const onSuccess = function (response) {
  return _.get(response, "data", response);
};

const onError = function (error) {
  if (Axios.isCancel(error)) {
    return Promise.reject(error);
  }

  if (error.response) {
    console.error("FAILED Response:", error.response);
    console.log("FAILED Status:", error.response.status);
    console.log("FAILED Data:", error.response.data);
    console.log("FAILED Headers:", error.response.headers);

    switch (error.response.status) {
      case 401:
        return refreshAccessToken()
          .then(() => {
            return Axios(error.config);
          })
          .catch((refreshError) => {
            return Promise.reject(refreshError);
          });
      case 422:
        const errors = _.get(error, "response.data.errors", null);
        const message = _.get(
          error,
          "response.data.message",
          "Please check your input"
        );
        if (errors) {
          error.response.data.message = _.get(
            _.values(errors),
            "[0][0]",
            message
          );
          error.response.data["errors"] = errors;
        } else if (message) {
          error.response.data.message = message;
        }
        break;
      default:
        break;
    }
    return Promise.reject(error.response.data);
  } else {
    console.log("Error Message:", error.message);
  }

  return Promise.reject(error);
};

request.interceptors.response.use(onSuccess, onError);

request.defaults.headers["accept-language"] = "en";
request.defaults.headers["x-lang"] = 0;
request.defaults.headers["access-control-allow-origin"] = "*";
request.defaults.headers["Authorization"] = `Bearer ${localStorage.getItem(
  KEYS.TOKEN
)}`;
request.defaults.baseURL = PRODUCTION_API;

request["setSession"] = function ({ token }) {
  request.defaults.headers[AUTH_HEADER] = token;
};

request["getSession"] = function () {
  return request.defaults.headers[AUTH_HEADER];
};

request["removeSession"] = function () {
  delete this.defaults.headers[AUTH_HEADER];
};

const refreshAccessToken = async () => {
  const refreshToken = localStorage.getItem(KEYS.REFRESH_TOKEN);
  delete request.defaults.headers["Authorization"];

  localStorage.removeItem(KEYS.TOKEN);
  localStorage.removeItem(KEYS.REFRESH_TOKEN);

  return new Promise(async (resolve, reject) => {
    try {
      const newAccessToken = await request.post(
        `/auth/refresh`,
        {},
        {
          headers: {
            Authorization: `Bearer ${refreshToken}`,
          },
        }
      );

      const token = newAccessToken.access_token;
      const refresh_token = newAccessToken.refresh_token;
      localStorage.setItem(KEYS.TOKEN, token);
      localStorage.setItem(KEYS.REFRESH_TOKEN, refresh_token);
      request.defaults.headers["Authorization"] = `Bearer ${token}`;
      resolve(token);
    } catch (error) {
      console.error(error.message);
      reject(error);
    } finally {
      window.location = "/";
    }
  });
};

export default request;
