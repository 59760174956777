import React from "react";
import { AiFillShop } from "react-icons/ai";
import { LuScanFace } from "react-icons/lu";

const LeftTabs = (props) => {
  return (
    <div className="flex lg:flex-col lg:py-4 xl:py-6 px-4 lg:px-0 gap-8 rounded-[20px] shadow-custom w-full lg:w-auto justify-center lg:justify-start">
      <div
        className="flex flex-col lg:flex-row"
        onClick={() => props.setShowPage("shop")}
        title="Shop"
      >
        <div
          className={`p-[2px] rounded-b lg:rounded-bl-none lg:rounded-r ${
            props.showPage === "shop" ? "bg-main" : ""
          }`}
        ></div>
        <div
          className={`px-1 py-4 lg:py-1 lg:px-4 xl:px-6 cursor-pointer text-center ${
            props.showPage === "shop" ? "text-main" : "text-first"
          }`}
        >
          <AiFillShop className="text-3xl mx-auto block" />
        </div>
      </div>
      <div
        className="flex flex-col lg:flex-row"
        onClick={() => props.setShowPage("scan")}
        title="Scan"
      >
        <div
          className={`p-[2px] rounded-b lg:rounded-bl-none lg:rounded-r ${
            props.showPage === "scan" ? "bg-main" : ""
          }`}
        ></div>
        <div
          className={`px-1 py-4 lg:py-1 lg:px-4 xl:px-6 cursor-pointer text-center ${
            props.showPage === "scan" ? "text-main" : "text-first"
          }`}
        >
          <LuScanFace className="text-3xl mx-auto block" />
        </div>
      </div>
    </div>
  );
};

export default LeftTabs;
