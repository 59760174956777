import React from "react";
import CardmenaLogo from "../../../components/Layout/CardmenaLogo";
import PropertyDetails from "../../../components/Layout/PropertyDetails";
import Button from "../../../components/Ui/Button";
import useUser from "../../../hooks/useUser";

const Header = (props) => {
  const { userInformation, branchInfo } = props;
  const { logout } = useUser();

  return (
    <section className="flex items-center w-full flex-wrap lg:flex-nowrap gap-4 lg:gap-0">
      <PropertyDetails
        companyName={userInformation?.company.companyLegalName}
        branchName={branchInfo?.name}
        username={userInformation?.name}
        companyLogo={userInformation?.company.lightLogo}
      />
      <CardmenaLogo />
      <div className="flex justify-center lg:justify-end w-full lg:w-1/3 order-3 lg:order-3">
        <Button onClick={logout} theme={"secondary"}>
          Log Out
        </Button>
      </div>
    </section>
  );
};

export default Header;
