import React from "react";
import Empty from "../../../components/ScanPage/Empty";
import Scanning from "../../../components/ScanPage/Scanning";
import PassInformation from "../../../components/ScanPage/PassInformation";
import ErrorPass from "../../../components/ScanPage/ErrorPass";

const ScanPage = (props) => {
  const {
    isScanning,
    isScanned,
    pass,
    addPoints,
    removePoints,
    redeemPoints,
    status,
    transactionsCustomerData,
    isError,
    setShowBarcodeReader,
    passMedia,
  } = props;

  return (
    <div className="flex-1">
      {!isScanning && !isScanned && !isError && <Empty />}
      {isScanning && !isScanned && !isError && <Scanning status={status} />}
      {!isScanning && isScanned && !isError && (
        <PassInformation
          pass={pass}
          addPoints={addPoints}
          removePoints={removePoints}
          redeemPoints={redeemPoints}
          transactionsCustomerData={transactionsCustomerData}
          setShowBarcodeReader={setShowBarcodeReader}
          passMedia={passMedia}
        />
      )}
      {!isScanning && isScanned && isError && <ErrorPass isError={isError} />}
    </div>
  );
};

export default ScanPage;
