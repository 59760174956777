import React, { useState, useEffect, useRef } from "react";
import BarcodeReader from "react-barcode-reader";
import { useFormik } from "formik";
import * as Yup from "yup";
import Button from "../../Ui/Button";

const Popup = (props) => {
  const [noteInputError, setNoteInputError] = useState("");
  const [redPointsInputError, setRedPointsInputError] = useState("");
  const noteInputRef = useRef(null);
  const redPointsInputRef = useRef(null);
  const {
    popupType,
    closeHandler,
    okHandler,
    points,
    minimum_redeem_points,
    max_redeem_points,
  } = props;

  const actionStr =
    popupType === 2 || popupType === 5
      ? "Write down the reward you want to redeem:"
      : "Write down the total of the invoice:";

  const formik = useFormik({
    initialValues: {
      note: "",
      redPoints: "",
    },
    validationSchema: Yup.object({
      note: Yup.string().required("This field is required"),
      redPoints: Yup.number()
        .required("This field is required")
        .min(
          minimum_redeem_points,
          `Please enter a value more than ${minimum_redeem_points}`
        )
        .max(
          points > max_redeem_points ? max_redeem_points : points,
          `Please enter a value less than ${
            points > max_redeem_points ? max_redeem_points : points
          }`
        ),
    }),
  });

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      submitHandler();
    }
    if (event.key === "Escape") {
      closeHandler();
    }
  };

  const submitHandler = () => {
    // ADD && REMOVE - STAMPS + REDEEM - VOUCHER
    if (popupType === 0 || popupType === 1 || popupType === 6) okHandler();

    // ADD && REMOVE - POINTS
    if (popupType === 3 || popupType === 4) {
      if (!formik.errors.note && noteInputRef.current.value.length > 0) {
        okHandler(noteInputRef.current.value);
        setNoteInputError("");
        noteInputRef.current.value = null;
      } else if (noteInputRef.current.value.length === 0) {
        setNoteInputError("This field is required");
      }
    }

    // REDEEM - STAMPS
    if (popupType === 2) {
      if (
        !formik.errors.note &&
        noteInputRef.current.value.length > 0 &&
        points > 0
      ) {
        okHandler(noteInputRef.current.value);
        setNoteInputError("");
        noteInputRef.current.value = null;
      } else if (points === 0 && noteInputRef.current.value.length > 0) {
        setNoteInputError("Customer does not have any stamps to redeem");
      } else if (noteInputRef.current.value.length === 0) {
        setNoteInputError("This field is required");
      }
    }

    // REDEEM - POINTS
    if (popupType === 5) {
      if (
        !formik.errors.note &&
        !formik.errors.redPoints &&
        noteInputRef.current.value.length > 0 &&
        redPointsInputRef.current.value.length > 0 &&
        points > 0
      ) {
        okHandler(noteInputRef.current.value, redPointsInputRef.current.value);
        setNoteInputError("");
        setRedPointsInputError("");
        noteInputRef.current.value = null;
        redPointsInputRef.current.value = null;
      } else if (
        points === 0 &&
        noteInputRef.current.value.length > 0 &&
        redPointsInputRef.current.value.length > 0
      ) {
        setNoteInputError("Customer does not have any stamps to redeem");
      } else if (noteInputRef.current.value.length === 0) {
        setNoteInputError("This field is required");
      } else if (redPointsInputRef.current.value.length === 0) {
        setRedPointsInputError("This field is required");
      }
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  return (
    <div className="w-full h-full fixed bg-black500 inset-0 z-10">
      <div className="bg-white fixed top-[50%] left-[50%] z-50 -translate-y-[50%] -translate-x-[50%] rounded-2xl py-12 px-16">
        {(popupType === 0 || popupType === 1 || popupType === 6) && (
          <p className="font-bold text-first tracking-wider	text-center pb-4">
            Are you sure you want to continue?
          </p>
        )}
        {(popupType === 2 ||
          popupType === 3 ||
          popupType === 4 ||
          popupType === 5) && (
          <>
            <p className="font-bold text-first tracking-wider	text-center mb-4">
              {actionStr}
            </p>
            <input
              type={`${popupType === 2 || popupType === 5 ? "text" : "number"}`}
              id="note"
              name="note"
              ref={noteInputRef}
              className="bg-secondary rounded-[10px] text-first p-2 outline-none block w-full h-12"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              onKeyDown={handleKeyPress}
              placeholder={popupType === 5 ? "american_coffee" : ""}
            />
            {formik.errors.note &&
              formik.touched.note &&
              !(noteInputError.length > 0) && (
                <p className="text-red text-sm mt-4">{formik.errors.note}</p>
              )}
            {noteInputError.length > 0 && (
              <p className="text-red text-sm mt-4">{noteInputError}</p>
            )}
            {popupType === 5 && (
              <>
                <input
                  type="number"
                  id="redPoints"
                  name="redPoints"
                  ref={redPointsInputRef}
                  className="bg-secondary rounded-[10px] text-first p-2 outline-none block w-full h-12 mt-4"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  onKeyDown={handleKeyPress}
                  placeholder="75"
                  min={minimum_redeem_points}
                  max={max_redeem_points}
                />
                {formik.errors.redPoints &&
                  formik.touched.redPoints &&
                  !(redPointsInputError.length > 0) && (
                    <p className="text-red text-sm mt-4">
                      {formik.errors.redPoints}
                    </p>
                  )}
                {redPointsInputError.length > 0 && (
                  <p className="text-red text-sm mt-4">{redPointsInputError}</p>
                )}
                <div className="flex justify-between text-center my-4">
                  <div className="text-sm">
                    Maximum Redeem Points:
                    <br />
                    <span className="text-green">{max_redeem_points}</span>
                  </div>
                  <div className="text-sm">
                    Minimum Redeem Points:
                    <br />
                    <span className="text-red">{minimum_redeem_points}</span>
                  </div>
                </div>
              </>
            )}
          </>
        )}
        <div className="grid grid-cols-2 gap-4 mt-4">
          <Button onClick={closeHandler} theme={"secondary"}>
            Cancel
          </Button>
          <Button onClick={submitHandler} theme={"primary"}>
            Ok
          </Button>
        </div>
        <BarcodeReader
          onError={(error) => console.error(error)}
          onScan={(data) => {
            if (popupType === 5) {
              noteInputRef.current.value = data.split(":")[0];
              redPointsInputRef.current.value = data.split(":")[1];
            } else noteInputRef.current.value = data;
          }}
          key={"popupElement"}
        />
      </div>
    </div>
  );
};

export default Popup;
