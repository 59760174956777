import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import TextField from "@mui/material/TextField";
import Button from "../Ui/Button";

import CardmenaLogo from "../../assets/logos/logo.png";

const SecretForm = (props) => {
  const formik = useFormik({
    initialValues: {
      secret: "",
    },
    onSubmit: () => {
      props.onSubmit(formik.values.secret);
      formik.resetForm();
    },
    validationSchema: Yup.object({
      secret: Yup.string().required("Your company secret code is required"),
    }),
  });

  return (
    <div className="rounded-[20px] shadow-custom py-8 lg:py-10 px-4 lg:px-8 max-w-lg mx-auto my-auto">
      <div className="flex items-center justify-center">
        <div className="w-full">
          <img
            src={CardmenaLogo}
            alt="Cardmena Logo"
            className="mx-auto max-w-[60%]"
          />
          <h1 className="text-main text-center text-2xl mt-8 mb-4 font-semibold leading-8">
            Let's do the activation
          </h1>
          <p className="text-first text-center mb-4">
            Please enter your company secret code to continue
          </p>
          {props.isError !== "" && (
            <p className="text-red text-sm mb-4 text-center">{props.isError}</p>
          )}
          <form>
            <div className="mt-4 mb-4">
              <TextField
                required
                type="text"
                id="secret"
                name="secret"
                label="Company Secret Code"
                placeholder="Enter your company secret code here.."
                fullWidth
                error={formik.errors.secret && formik.touched.secret}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.secret}
              />
              {formik.errors.secret && formik.touched.secret && (
                <p className="text-left text-red text-sm mt-2">
                  {formik.errors.secret}
                </p>
              )}
            </div>
            <Button
              onClick={() => {
                formik.handleSubmit();
              }}
              theme={"primary"}
              isFullWidth={true}
              type={"button"}
            >
              Next
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SecretForm;
