import React, { useState } from "react";
import Dashboard from "../../../components/StatPage/Dashboard";
import ClientTransactions from "../../../components/StatPage/ClientTransactions";

const StatPage = (props) => {
  const {
    totalTransactionsClientData,
    transactionsClientData,
    totalRedeems,
    totalPasses,
    fetchPassData,
  } = props;
  const [seeAll, setSeeAll] = useState(false);

  const seeAllHandler = () => {
    setSeeAll((prevStat) => !prevStat);
  };

  return (
    <div className="flex flex-1 gap-4 xl:gap-6 flex-wrap lg:flex-nowrap">
      {!seeAll && (
        <Dashboard
          totalTransactionsClientData={totalTransactionsClientData}
          totalRedeems={totalRedeems}
          totalPasses={totalPasses}
        />
      )}
      <ClientTransactions
        data={transactionsClientData}
        seeAllHandler={seeAllHandler}
        seeAll={seeAll}
        fetchPassData={fetchPassData}
      />
    </div>
  );
};

export default StatPage;
