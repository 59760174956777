import React from "react";
import Row from "./Row";

const Table = (props) => {
  const fullTableStyle =
    "max-h-[510px] overflow-x-hidden overflow-y-scroll pr-6";

  const numOfEntries = props.seeAll
    ? props.data.length
    : props.data.length >= 10
    ? 10
    : props.data.length;

  return (
    <div className={`${props.seeAll ? fullTableStyle : ""}`}>
      <div className="grid grid-cols-custom4 p-1.5 mb-1.5 rounded-[10px]">
        <div className="text-center">
          <span className="text-xs font-semibold text-header">Branch</span>
        </div>
        <div className="text-center">
          <span className="text-xs font-semibold text-header">Employee</span>
        </div>
        <div className="text-center">
          <span className="text-xs font-semibold text-header">Action</span>
        </div>
        <div className="text-center">
          <span className="text-xs font-semibold text-header">Date & Time</span>
        </div>
      </div>

      {props.data.slice(0, numOfEntries).map((item) => {
        return <Row key={item.id} data={item} />;
      })}
    </div>
  );
};

export default Table;
